<template>
  <div class="discussion-content">
    <div class="discussion-wrap">
      <div class="__header">
        <h4>Discussion board</h4>
        <div class="dropdown">
          <button
            v-if="programId != ''"
            class="btn cta-small dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Create Discussion
          </button>
        </div>
      </div>
      <div class="__body" v-if="isLoading == false">
        <ul class="list-unstyled" v-if="discussions.length > 0">
          <li
            v-for="(discussion, discussionIndex) in discussions"
            :key="discussionIndex"
          >
            <router-link
              :to="{ name: 'discussionReply', params: { id: discussion.id } }"
              class="discussion-card"
            >
              <div class="stamp">{{ discussion.created_date }}</div>
              <div class="thumbnail">
                <img :src="discussion.image" alt="user image" />
              </div>
              <div class="_card-body row">
                <div class="discuss-body col-md-8">
                  <div class="header">
                    <h5>
                      {{ discussion.first_name }} {{ discussion.last_name }}
                    </h5>
                    <span class="physics">{{ discussion.subject }}</span>
                  </div>
                  <p class="status">
                    {{ discussion.created_date }} - {{ discussion.time }}
                  </p>
                  <p class="body">
                    {{ discussion.discussion }}
                  </p>
                </div>
                <div class="_action-bar col-md-4">
                  <ul class="list-unstyled thumblist">
                    <li
                      v-for="(child, childIndex) in discussion.children.slice(
                        0,
                        3
                      )"
                      :key="childIndex"
                    >
                      <img :src="child.image" alt="" />
                    </li>
                    <li v-if="discussion.children.length > 3">
                      <a href="#">+{{ discussion.children.length - 3 }}</a>
                    </li>
                  </ul>
                  <p>{{ discussion.children.length }} comments</p>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="empty" v-else-if="programId == ''">
          <p>Please subscribe a course to start discussion</p>
        </div>
        <div class="empty" v-else>
          <p>No discussion found</p>
        </div>
      </div>
      <webinar-loader v-else></webinar-loader>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Start Discussion</h5>
            <button
              type="button"
              class="btn-close"
              ref="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveDiscussion">
            <div class="modal-body">
              <div class="form-group col-md-12">
                <div class="mb-3">
                  <label class="form-label">Subject</label>
                  <multiselect
                    v-model="subject"
                    :options="subjects"
                    label="title"
                    @select="getTeachers"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.subject.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div v-if="errors && errors.subject" class="error-msg">
                    {{ errors.subject[0] }}
                  </div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <div class="mb-3">
                  <label class="form-label">Teacher</label>
                  <multiselect
                    v-model="teacher"
                    :options="teachers"
                    label="first_name"
                    :loading="isTeacherLoading"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.teacher.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div v-if="errors && errors.teacher" class="error-msg">
                    {{ errors.teacher[0] }}
                  </div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <div class="mb-3">
                  <label class="form-label">Discussion</label>
                  <textarea
                    class="form-control"
                    v-model="discussion"
                  ></textarea>
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.discussion.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div v-if="errors && errors.discussion" class="error-msg">
                    {{ errors.discussion[0] }}
                  </div>
                </div>
              </div>
              <!-- <div class="paging-wrapper">
                <v-pagination
                  v-model="completedSchedulePage"
                  :pages="completedScheduleTotalPages"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="completedScheduleChangeHandler"
                  v-if="completedScheduleTotalClass > 0"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <input type="submit" class="btn cta-primary" value="Save" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService";
import DiscussionService from "@/services/DiscussionService";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import errorLog from "@/errorLog";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },

  name: "DiscussionBoard",
  components: {
    WebinarLoader,
    Multiselect
  },
  created() {
    this.getActiveProgram();
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  data() {
    return {
      discussions: [],
      isLoading: true,
      subject: "",
      subjects: [],
      programId: "",
      teacher: "",
      teachers: [],
      isTeacherLoading: false,
      discussion: "",
      errors: {}
    };
  },
  validations() {
    return {
      discussion: {
        required: helpers.withMessage("Please enter comment", required),
        maxLength: maxLength(2400)
      },
      teacher: {
        required: helpers.withMessage("Please select teacher", required)
      },
      subject: {
        required: helpers.withMessage("Please select subject", required)
      }
    };
  },
  methods: {
    getActiveProgram() {
      ProgramService.getActiveProgram()
        .then(result => {
          let programId = "";
          if (result.data.program != null) {
            programId = result.data.program.program_id;
          }
          this.subjects = result.data.subjects;
          this.programId = programId;
          this.getDiscussion(programId);
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getDiscussion(programId) {
      const data = {
        program_id: programId
      };
      DiscussionService.getParentDiscussions(data)
        .then(result => {
          this.discussions = result.data;
          this.isLoading = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getTeachers: function() {
      this.teacher = "";
      this.teachers = [];
      this.isTeacherLoading = true;
      const data = {
        subject_id: this.subject,
        program_id: this.programId
      };
      DiscussionService.getTeachers(data)
        .then(result => {
          this.isTeacherLoading = false;
          this.teachers = result.data;
        })
        .catch(error => {
          this.isTeacherLoading = false;
          errorLog.log(error);
        });
    },
    saveDiscussion: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const data = {
        subject: this.subject,
        teacher: this.teacher,
        discussion: this.discussion,
        program_id: this.programId
      };
      DiscussionService.saveDiscussion(data)
        .then(result => {
          if (result.data.status == "error") {
            this.$toast.error(result.data.message);
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message || {};
          } else {
            this.$toast.success(result.data.message);
            this.getDiscussion(this.programId);
            this.$refs.closeModal.click();
            this.subject = "";
            this.teacher = "";
            this.discussion = "";
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
